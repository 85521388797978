import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { onAuthStateChanged } from '@firebase/auth';
import axios from 'axios';

import { firebaseAuth, signInWithTwitter } from './firebase';

const maxAttempts = 20;

const checkUser = async (
  authToken: string,
) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}/status`, {}, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return {
      userExists: !!data?.success,
      words: data?.words ?? [],
      attempts: data?.attempts,
      isRightAnswer: data?.isRightAnswer,
      position: data?.position ?? 0,
    };
  } catch (e) {
    //
  }

  return { userExists: false, words: [], attempts: 0, isRightAnswer: false };
}

const submitWords = async (
  authToken: string,
  words: string[],
) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}/submit-words`, {
      words,
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return {
      success: data.success,
      isRightAnswer: data.isRightAnswer,
      position: data?.position ?? 0,
    };
  } catch (e) {
    //
  }
  return { success: false, isRightAnswer: false, position: 0 };
}

const WordsSubmit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [requiresRegister, setRequiresRegister] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authToken, setAuthToken] = useState<string>('');
  const [attempts, setAttempts] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [restoredWords, setRestoredWords] = useState<string[]>([]);
  const [words, setWords] = useState<{ [index: number]: string }>({});
  const [hasAnswered, setHasAnswered] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      let updatedWords = {};

      if (user && firebaseAuth.currentUser) {
        try {
          const newAuthToken = await firebaseAuth.currentUser.getIdToken();
          setAuthToken(newAuthToken)
          const {
            userExists,
            words: prevWords,
            attempts: prevAttempts,
            isRightAnswer,
            position: updatedPosition,
          } = await checkUser(newAuthToken);
          setRestoredWords(prevWords);
          updatedWords = prevWords.reduce((acc: { [index: number]: string }, word: string, index: number) => {
            acc[index] = word;
            return acc;
          }, {});
          setRequiresRegister(!userExists);
          setAttempts(prevAttempts);
          setHasAnswered(isRightAnswer);
          setPosition(updatedPosition);
        } catch (e) {
          //
        }
      }

      setWords(updatedWords);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  const onClickConnectWithTwitter = async () => {
    if (isLoading) return;
    window.localStorage.removeItem('submitted');

    setWords({});
    setErrorMessage('');
    setIsLoading(true);
    setAuthToken('');
    setWords({});

    const result = await signInWithTwitter();
    if (result?.errorMessage || !result?.user) {
      setErrorMessage(result?.errorMessage ?? 'Failed to sign in with Twitter!');
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  }

  const onLogoutClick = async () => {
    setAuthToken('');
    setWords({});
    await firebaseAuth.signOut()
  }

  const inputChanged = !restoredWords?.length
    || Object.values(words).some((word, index) => restoredWords[index] !== word);

  const isSubmitDisabled = isSubmitting
    || Object.values(words).filter((word) => !!word).length !== 12
    || !inputChanged
    || maxAttempts - attempts <= 0;

  const onSubmitClick = async () => {
    if (isSubmitDisabled) return;
    setIsSubmitting(true);
    setErrorMessage('');

    const {
      success: succeeded,
      isRightAnswer,
      position: updatedPosition,
    } = await submitWords(authToken, Object.values(words));
    if (!succeeded) {
      setErrorMessage('Failed to submit words!');
    } else {
      setRestoredWords(Object.values(words));
      setAttempts(attempts ? attempts + 1 : 1);
      setHasAnswered(isRightAnswer);
      setErrorMessage(isRightAnswer ? '' : 'Invalid seed phrase!');
      setPosition(updatedPosition);
    }

    setIsSubmitting(false);
  }

  const onGoBackClick = async () => {
    window.open('https://kaisledger.com');
  }

  if (authToken && requiresRegister) {
    return (
      <>
        <p>You must register first.</p>
        <br/>
        <br/>
        <StyledButton
          onClick={() => {
            window.open('https://register.kaisledger.com/', '_blank');
          }}
        >
          Register
        </StyledButton>
        <GoBackButton onClick={onGoBackClick}>Go back to kaisledger.com</GoBackButton>
      </>
    )
  }

  if (isSubmitting) {
    return (
      <p>Submitting...</p>
    )
  }

  return (
    <>
      {!authToken && <StyledButton onClick={onClickConnectWithTwitter}>Connect Twitter</StyledButton>}
      {!!authToken && (
        <>
          <p>Access your wallet with your secret recovery phrase.</p>
          <br/>
          <br/>
          <InputsWrapper>
            {new Array(12).fill(0).map((_, index) => (
              <WordInputWrapper>
                <p>Word {index + 1}</p>
                <StyledInput
                  key={`input-${index}`}
                  type="text"
                  disabled={hasAnswered}
                  value={(words[index] ?? '').toLowerCase()}
                  onChange={(e) => setWords({ ...words, [index]: ((e?.target?.value ?? '').toLowerCase()) as string })}
                />
              </WordInputWrapper>
            ))}
          </InputsWrapper>
          {!hasAnswered && (
            <>
              <SeedphraseWarning>Do not enter your real seed phrase.</SeedphraseWarning>
              <br/>
              <br/>
              <StyledButton onClick={onSubmitClick} $disabled={isSubmitDisabled}>
                {attempts ? 'Update' : 'Submit'}
              </StyledButton>
              {!!attempts && <UpdatesLeft>{maxAttempts - attempts} edits remaining</UpdatesLeft>}
            </>
          )}
          {hasAnswered && (
            <SuccessMessage>Congratulations, you are agent{position > 0 ? ` #${position}` : ''}!</SuccessMessage>
          )}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {/*@ts-ignore*/}
          <AccountText>Twitter as {firebaseAuth.currentUser?.reloadUserInfo?.screenName ?? firebaseAuth.currentUser?.displayName}</AccountText>
          <LogoutButton onClick={onLogoutClick}>Logout</LogoutButton>
        </>
      )}
      {errorMessage && !authToken && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <GoBackButton onClick={onGoBackClick}>Go back to kaisledger.com</GoBackButton>
    </>
  );
}


const App = () => {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Title>Kai's Ledger is solved.</Title>
        {false && <WordsSubmit />}
      </Wrapper>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Courier Prime', monospace;
    background-color: #000;
    color: #fff;
    text-align: center;
  }
  
  * {
    padding: 0;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 200px);
  padding: 100px 0;
`;

const StyledButton = styled.div<{ $disabled?: boolean }>`
  margin-bottom: 20px;
  padding: 10px 20px;
  border: 1px solid #fff;
  cursor: pointer;

  ${({ $disabled }) => $disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
    
  &:hover {
    opacity: 0.8;
  }
    
  &:active {
    opacity: 0.5;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 20px;
  color: #f00;
  font-size: 14px;
`;

const AccountText = styled.p`
  margin-top: 40px;
  color: #ffffff;
  font-size: 12px;
`;

const UpdatesLeft = styled.p`
  color: #ffd91a;
  font-size: 14px;
`;

const SuccessMessage = styled.p`
  color: #00ff00;
  font-size: 14px;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  text-align: center;
`;

const LogoutButton = styled.p`
  margin-top: 10px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const GoBackButton = styled.p`
  margin-top: 40px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled.input`
  font-family: 'Courier Prime', monospace;
  font-size: 16px;
  margin-top: 5px;
  width: 200px;
  padding: 5px;

  &:focus {
    outline: 2px solid #d900e0;
  }
`;

const WordInputWrapper = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const InputsWrapper = styled.div`
  margin-bottom: 40px;
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

const SeedphraseWarning = styled.p`
  color: #ff0000;
`;

export default App;
